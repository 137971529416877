import { useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { fetchJWT } from "./createServiceClient";

/**
 * Fetch the JWT token with a few retry attempts
 * @param maxRetries Max number of times to retry the request
 * @param delayInterval Time between retries
 * @returns
 */
const retryFetchJWT = async (
  maxRetries = 3,
  delayInterval = 5000,
  attempts = 0
): Promise<string> => {
  try {
    return await fetchJWT({});
  } catch (error) {
    if (attempts >= maxRetries - 1) throw error;

    // Calculate exponential delay
    const exponentialDelay = delayInterval * 2 ** attempts;

    await new Promise((resolve) => setTimeout(resolve, exponentialDelay));
    return retryFetchJWT(maxRetries, delayInterval, attempts + 1);
  }
};

/**
 * Returns whether the JWT is expired or not
 * @param token Current JWT token in cache
 * @returns true if JWT can't be decoded
 */
export const isTokenExpired = (token: string) => {
  try {
    const { exp } = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    // Need to explicitly check for undefined because exp=0 is truthy
    return exp !== undefined ? exp < currentTime : false; // No expiry = not expired
  } catch {
    // If decoding fails, consider the token invalid
    return true;
  }
};

/**
 * Hook for caching and refetching a JWT token when it expires
 * @returns
 */
const useJWT = ({
  maxRetries,
  delayInterval,
}: {
  maxRetries?: number;
  delayInterval?: number;
}): { getOrGenerateToken: () => Promise<string> } => {
  const token = useRef("");

  const retries = maxRetries ?? 3; // Default to 3 if undefined
  const interval = delayInterval ?? 5000; // Default to 5000ms if undefined

  const getOrGenerateToken = async () => {
    if (!token.current || isTokenExpired(token.current)) {
      token.current = await retryFetchJWT(retries, interval);
    }
    return token.current;
  };

  return { getOrGenerateToken };
};

export default useJWT;
