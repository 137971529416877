import { gettext } from "django-i18n";

import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import CancelIcon from "@deprecated/material-ui/svg-icons/navigation/cancel";

import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";

import { debounce, isEmpty, uniqueId } from "lodash";

import Tooltipify from "_common/components/tooltipify";
import { makeToast } from "../toast/actions";
/**
 * Input component that can replace an html multi-select.
 */
class Tags extends Component {
  constructor(props) {
    super(props);

    const { suggestions, initialValue, value, name } = props;

    let tags = [];

    if (!isEmpty(initialValue)) {
      tags = initialValue;
    }

    if (!isEmpty(value)) {
      tags = value;
    }

    this.state = {
      term: "",
      suggestions,
      tags,
      name,
      uId: uniqueId(),
    };

    this.handleSuggestionsFetchRequested = debounce(
      this.handleSuggestionsFetchRequested,
      300
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { suggestions, value } = nextProps;

    this.setState({
      suggestions,
      tags: value || [],
    });
  }

  handleChange = (tags) => {
    const { onChange } = this.props;

    // If we have an onChange, allow the parent to control what tags will show up
    !onChange && this.setState({ tags });

    onChange && onChange(tags);
  };

  handleSuggestionsInputChange = (e, { newValue }) => {
    this.setState({ term: newValue });
  };

  handleSuggestionsInputKeyUp = (e) => {
    e.stopPropagation();

    if (e.which === 27) {
      this.setState({
        term: "",
      });
    }

    if (e.which === 13) {
      const suggestionValue = e.target.value || "";
      if (suggestionValue !== "") {
        this.handleSuggestionSelected(e, { suggestionValue });
      }
    }
  };

  handleSuggestionsInputKeyPress = (e) => {
    if (e.which !== 13) {
      return;
    }

    e.preventDefault();
  };

  handleSuggestionsInputBlur = (e) => {
    const suggestionValue = e.target.value;
    suggestionValue && this.handleSuggestionSelected(e, { suggestionValue });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { onTermChange } = this.props;
    onTermChange && onTermChange(value);
  };

  handleSuggestionsClearRequested = () => {
    const { onTermChange } = this.props;
    onTermChange && onTermChange("");
  };

  handleSuggestionSelected = (e, { suggestionValue }) => {
    const value = suggestionValue.trim();
    const lowerCaseValue = value.toLowerCase();
    const { allowParentDuplicates, dispatch, parentTags } = this.props;

    this.setState({
      term: "",
    });

    // Don't check parent tags for duplicates if allowParentDuplicates
    const tags = allowParentDuplicates
      ? this.state.tags
      : [...parentTags, ...this.state.tags];
    const existingTag = tags.find(
      (element) => lowerCaseValue === element.toLowerCase()
    );

    if (existingTag) {
      const toastMessage = gettext(`Tag '${existingTag}' already exists.`);
      dispatch(makeToast("error", toastMessage));
      return;
    }

    const newTags = this.state.tags.concat(value);

    this.handleChange(newTags);
  };

  getSuggestionValue(suggestion) {
    if (typeof suggestion === "object" && !isEmpty(suggestion.name)) {
      return suggestion.name;
    }

    return suggestion;
  }

  getSuggestions() {
    const { suggestions, tags } = this.state;

    return suggestions
      .filter(
        (suggestion) =>
          !tags.some(
            (tag) =>
              this.getSuggestionValue(suggestion).toLowerCase() ===
              tag.toLowerCase()
          )
      )
      .slice(0, 5);
  }

  renderSuggestion = (suggestion) => (
    <span>{this.getSuggestionValue(suggestion)}</span>
  );

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { focused, value, name } = this.state;

    const expanded = value || focused;
    return (
      <div>
        {expanded}
        <div aria-label={`Add a tag to ${name}`} {...containerProps}>
          {children}
        </div>
      </div>
    );
  };

  renderAutosuggest = (props) => {
    const { id, addTag, ...inputProps } = props;

    const { name, readOnly, maxLength } = this.props;

    const { term, uId } = this.state;

    const theme = {
      container: "sde-tags-autosuggest-container",
      suggestionsContainer: "sde-tags-suggestions",
      suggestionsList: "sde-tags-suggestions-list",
      suggestion: "sde-tags-suggestion",
      suggestionHighlighted: "sde-tags-suggestion-active",
    };
    return (
      <div className="sde-tags-autosuggest">
        <input type="hidden" {...inputProps} />
        <Autosuggest
          theme={theme}
          id={uId} // passing uniqueId to pass accessibility criteria for autosuggest
          suggestions={this.getSuggestions()}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          inputProps={{
            name,
            id,
            placeholder: gettext("Add a tag..."),
            type: readOnly ? "hidden" : "text",
            value: term,
            maxLength,
            onChange: this.handleSuggestionsInputChange,
            onKeyUp: this.handleSuggestionsInputKeyUp,
            onKeyPress: this.handleSuggestionsInputKeyPress,
            onBlur: this.handleSuggestionsInputBlur,
            className: "sde-tags-input sde-table-row-hidden",
            "data-cy": "new-tag-field",
          }}
          addTag={addTag}
        />
      </div>
    );
  };

  renderParentTags() {
    const { parentTooltip, parentTags } = this.props;

    return (
      parentTags &&
      parentTags.map((tag) => {
        const tagComponent = this.renderTag({
          key: tag,
          tag,
          tagClassName: "sde-application-tag",
        });

        return (
          <Tooltipify key={tag} message={parentTooltip}>
            {tagComponent}
          </Tooltipify>
        );
      })
    );
  }

  renderLayout = (tagComponents, inputComponent) => (
    <span>
      {this.renderParentTags()}
      {tagComponents}
      {inputComponent}
    </span>
  );

  renderTag = (props) => {
    const { key, onRemove, tag, tagClassName } = props;

    if (!tag) {
      return null;
    }

    const { name, readOnly } = this.props;

    return (
      <span key={key} className={tagClassName || "sde-tag"} data-cy="tag">
        {!readOnly && onRemove && (
          <CancelIcon
            data-testid="delete-tag"
            data-cy="delete-tag"
            onClick={() => onRemove(key)}
            className="sde-tag-remove"
          />
        )}
        <span className="sde-tag-text">{tag}</span>
        <input type="hidden" name={name} value={tag} />
      </span>
    );
  };

  render() {
    const { label, name, error } = this.props;

    const { tags } = this.state;

    const labelClasses = clsx("sde-tags-label", {
      "has-error": error,
    });

    return (
      <div className="sde-tags" data-cy="sde-tags">
        {label && <label className={labelClasses}>{label}</label>}
        <TagsInput
          name={name}
          value={tags}
          addKeys={[]}
          removeKeys={[]}
          renderLayout={this.renderLayout}
          renderTag={this.renderTag}
          renderInput={this.renderAutosuggest}
          onChange={this.handleChange}
        />
        {error && <div className="sde-tags-error">{error}</div>}
      </div>
    );
  }
}

Tags.defaultProps = {
  allowParentDuplicates: true,
  initialValue: [],
  value: [],
  suggestions: [],
  readOnly: false,
  parentTooltip: null,
  parentTags: [],
  maxLength: 50,
};

Tags.contextTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  initialValue: PropTypes.array,
  value: PropTypes.array,
  suggestions: PropTypes.array,
  readOnly: PropTypes.bool,
  parentTooltip: PropTypes.string,
  parentTags: PropTypes.array,
  onChange: PropTypes.func,
  onTermChange: PropTypes.func,
};

export { Tags };
